<template>
  <div class="promotion_order">
    <svg-icon icon-class="explain" class="explain" @click="show = true" />
    <van-overlay :show="show" z-index="1000">
      <div class="wrapper" @click.stop="show = false">
        <div class="block">
          <h3 class="title">
            订单推广状态<svg-icon icon-class="close" @click="show = false" />
          </h3>
          <div class="content">
            <p><span>已付款：</span>已付款还未确认收货的订单</p>
            <p><span>已收货：</span>已确认收货还未结算的订单</p>
            <p>
              <span>已结算：</span>按付款时间起算7个工作日后系统自动结算的订单
            </p>
            <p><span>已关闭：</span>在结算之前发生退款的订单，不分佣</p>
          </div>
        </div>
      </div>
    </van-overlay>
    <main>
      <transition name="van-fade">
        <div class="tips" v-show="tipsShow">
          <p>
            已付款的推广订单，产生待结算佣金，7个工作日后结算，在结算之前发生买家退款，不分佣。
          </p>
          <svg-icon icon-class="close" @click="tipsShow = false" />
        </div>
      </transition>
      <van-list
        v-model="loading"
        :finished="finished"
        :error.sync="error"
        :finished-text="order.length ? '没有更多了' : ''"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
        offset="0"
      >
        <div class="order_list">
          <div class="order" v-for="list in order" :key="list.dealNumber">
            <div class="order_info">
              <div class="info">
                <p><span>买家：</span>{{ list.memberTelNumber | tel }}</p>
                <p><span>订单号：</span>{{ list.dealNumber }}</p>
                <p><span>付款时间：</span>{{ list.finishTime | time }}</p>
                <p v-if="list.promoterOrderListItems[0].settlementState === 2">
                  <span>结算时间：</span
                  >{{ list.promoterOrderListItems[0].settlementTime | time }}
                </p>
              </div>
              <b class="state">{{
                list.promoterOrderListItems[0].settlementState === 1
                  ? "已付款"
                  : list.promoterOrderListItems[0].settlementState === 2
                  ? "已结算"
                  : ""
              }}</b>
            </div>
            <div
              class="goods_info"
              v-for="(item, index) in list.promoterOrderListItems"
              :key="index"
            >
              <div class="goods">
                <img v-lazy="item.filePath" />
                <div class="info">
                  <div class="top">
                    <b class="goods_name">{{ item.commodityName }}</b>
                    <p class="specification">
                      <span>商品规格：</span>{{ item.specsParameter }}
                    </p>
                  </div>
                  <div class="bottom">
                    <span class="num">×{{ item.quantity }}</span>
                    <div class="right">
                      <!-- <span class="state">已退款</span> -->
                      <span class="price"
                        >实付：<b
                          >￥{{ item.actualPayment.toFixed(2) }}</b
                        ></span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <p class="commission">
                商品佣金:<b>￥{{ item.commission.toFixed(2) }}</b>
              </p>
            </div>
            <b class="total_commission"
              >佣金合计：<span>￥{{ list.totalCommission }}</span></b
            >
          </div>
        </div>
      </van-list>
      <van-empty
        description="暂无推广订单"
        v-if="!loading && finished && !error && !order.length"
      />
    </main>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { formatDate } from "@/utils/validate";
export default {
  name: "promotion-order",
  data() {
    return {
      show: false,
      tipsShow: true,
      order: [],
      loading: false,
      finished: false,
      error: false,
      pageNum: 1,
      pages: 1,
    };
  },
  computed: {
    ...mapState("user", ["isLogin", "userInfo", "promoter"]),
  },
  filters: {
    tel(val) {
      return val.substr(0, 3) + "****" + val.substr(7);
    },
    time(val) {
      return formatDate(val);
    },
  },
  created() {},
  methods: {
    async onLoad() {
      await this.$API.user
        .promoterOrderList({
          object: {
            id: this.promoter.userId,
          },
          pageNum: this.pageNum,
          pageSize: 4,
        })
        .then((res) => {
          if (res.data.code != "SUCCESS") throw Error(res.data.msg);
          this.pages = res.data.pages;
          res.data.data.forEach((e) => {
            e.promoterOrderListItems.forEach((g) => {
              g.filePath = g.filePath.split(",")[0].endsWith(".mp4")
                ? g.filePath.split(",")[1]
                : g.filePath.split(",")[0];
            });
            this.order.push(e);
          });

          this.loading = false;
          if (this.pageNum >= this.pages) {
            this.finished = true;
          } else {
            this.pageNum++;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.error = true;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.promotion_order {
  padding-top: 46px;
  display: flex;
  flex-direction: column;
  height: 100%;
  .explain {
    position: fixed;
    right: 15px;
    top: 14px;
    font-size: 18px;
    z-index: 1000;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .block {
      width: 80%;
      border-radius: 20px;
      background-color: #fff;
      padding: 20px 26px;
      position: relative;
      .title {
        font-size: 18px;
        text-align: center;
        margin-bottom: 18px;
        .svg-icon {
          position: absolute;
          font-size: 16px;
          right: 15px;
          color: #666;
        }
      }
      .content {
        p {
          margin-bottom: 16px;
          color: #666;
          span {
            color: #a8a8a8;
          }
        }
        p:last-child {
          margin: 0;
        }
      }
    }
  }

  main {
    background: #f5f6fa;
    flex: 1;

    .tips {
      background: #feedd8;
      padding: 9px 15px;
      font-size: 12px;
      color: #666;
      display: flex;
      align-items: center;
      .svg-icon {
        margin-left: 6px;
        font-size: 14px;
      }
    }
    .order_list {
      padding: 0 15px;
      .order {
        background: #fff;
        border-radius: 10px;
        margin-top: 12px;
        padding: 16px 12px;
        .order_info {
          display: flex;
          justify-content: space-between;
          .info {
            p {
              margin-bottom: 8px;
              font-size: 12px;
              span {
                color: #999;
              }
            }
            p:first-child {
              font-size: 14px;
            }
          }
          .state {
            font-size: 16px;
            color: #fb4e10;
            flex-shrink: 0;
          }
        }
        .goods_info {
          margin-top: 6px;
          .goods {
            display: flex;
            img {
              width: 100px;
              height: 100px;
              object-fit: cover;
              border-radius: 10px;
              margin-right: 12px;
            }
            .info {
              display: flex;
              flex: 1;
              flex-direction: column;
              justify-content: space-between;
              .top {
                .goods_name {
                  font-size: 16px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
                .specification {
                  font-size: 12px;
                  span {
                    color: #999;
                  }
                }
              }
              .bottom {
                display: flex;
                justify-content: space-between;
                .num {
                  color: #999;
                }
                .right {
                  .state {
                    background: #ff2016;
                    color: #fff;
                    font-size: 12px;
                    line-height: 16px;
                    padding: 2px 4px;
                    border-radius: 4px;
                    margin-right: 4px;
                  }
                }
              }
            }
          }
          .commission {
            text-align: end;
            margin-top: 4px;
            line-height: 20px;
            b {
              color: #f91c12;
            }
          }
        }
        .total_commission {
          display: block;
          text-align: end;
          font-size: 16px;
          margin-top: 16px;
          span {
            color: #f91c12;
          }
        }
      }
    }
  }
}
</style>
